import { template as template_aa84336da72c4b69b06c1c38da47ae96 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_aa84336da72c4b69b06c1c38da47ae96(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
