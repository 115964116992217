import { template as template_e94c54e64f144f4c8227ea01b592a970 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e94c54e64f144f4c8227ea01b592a970(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
